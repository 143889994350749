import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { COLOR } from '@farewill/ui/tokens'
import { findVoucherInfo } from 'lib/api'
import { formatPriceInPounds } from 'lib/formatting/pricing'
import { getQueryParameters } from 'lib/url/getQueryParameters'
import {
  ONLINE_WILL_PRICE_IN_POUNDS,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
} from 'config'
import PATHS from 'paths'

import Overview from 'views/wills/components/Overview'
import DefaultLayout from 'layouts/DefaultLayout'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import { WILLS } from 'lib/products/constants'

const ReferralPage = ({ location }) => {
  const [voucherInfo, setVoucherInfo] = useState({})

  useEffect(() => {
    const { code } = getQueryParameters()
    if (!code) return

    findVoucherInfo(code)
      .then(({ discount, user }) => {
        setVoucherInfo({
          code,
          discountInPounds: formatPriceInPounds(
            discount * ONLINE_WILL_PRICE_IN_POUNDS
          ),
          referrer: user.preferredName,
        })
      })
      .catch(() => {
        navigate(PATHS.WILLS.OVERVIEW)
      })
  }, [])

  const [heroContent, setHeroContent] = useState({})

  useEffect(() => {
    const { referrer, discountInPounds, code } = voucherInfo

    if (!discountInPounds || !code) {
      return
    }

    if (heroContent.title || heroContent.subtitle) {
      return
    }

    setHeroContent({
      title: `${
        referrer || 'Someone'
      } is giving you ${discountInPounds} off your will`,
      subtitle: `Get ${discountInPounds} off the UK’s #1 will writer using the discount code ${code} at checkout.`,
    })
  }, [voucherInfo])

  return (
    <DefaultLayout
      location={location}
      title="Online Will Writing Service | Legal Will In 15 Minutes"
      description={`Our solicitor-approved guide helps you write a will online from the comfort of your own home. Rated ${TRUSTPILOT_RATING_OUT_OF_5} out of 5 by over ${roundDownToNearestHundred(
        TRUSTPILOT_REVIEWS_TOTAL
      )} customers on Trustpilot. National Will Writing Firm of the Year.`}
      product={WILLS}
      headerType="compact"
      footerType="compact"
      headerColor={COLOR.WHITE}
      canonicalPath={PATHS.WILLS.OVERVIEW}
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    >
      <Overview heroContent={heroContent} hidePricing />
    </DefaultLayout>
  )
}

ReferralPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default ReferralPage
