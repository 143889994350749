import axios from 'axios'
import * as Sentry from '@sentry/gatsby'

import * as CONSTANTS from './constants'

export interface Policies {
  cookies: {
    content: string
  }
  privacy: {
    content: string
  }
  terms: {
    content: string
  }
  offers: {
    content: string
  }
  // terms for funeral plans
  funeralplans: {
    content: string
  }

  // terms for at-need funerals
  directcremations: {
    content: string
  }
  // terms for online wills
  onlinewills: {
    content: string
  }
  // terms for telephone wills
  telephonewills: {
    content: string
  }
  // terms for probate
  probate: {
    content: string
  }
  // marketing assistant (Spotlight)
  marketingassistant: {
    content: string
  }
}

export const getPolicies = async (): Promise<Policies> => {
  try {
    const { data } = await axios.get<Policies>(CONSTANTS.GET_POLICIES_ENDPOINT)
    return data
  } catch (error) {
    console.error('Error fetching policies from API')
    // We want to break the build if we can't generate the policy pages
    throw error
  }
}

interface VoucherInfo {
  discount: number
  user: {
    preferredName: string
  }
}

export const findVoucherInfo = async (
  code: string
): Promise<VoucherInfo | undefined> => {
  let voucherInfo
  try {
    const { data } = await axios.post<VoucherInfo>(
      CONSTANTS.GET_VOUCHER_ENDPOINT,
      { code }
    )
    voucherInfo = data
  } catch (error) {
    switch ((error as any).response.status) {
      case 404:
        break
      default:
        Sentry.captureException(error)
        break
    }
  }
  return voucherInfo
}
